@import "../../css/theme";

#top-bar {
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 1px 3px rgba(0, 0, 0, 0.24);
  z-index: 101;
  position: relative;

  .nav-item {
    .nav-link {
      color: $color-font-light;
    }

    &:hover, &:focus {
      .nav-link, .fa {
        color: $color-font-light;
      }
    }

    &.active {
      .nav-link, .fa {
        color: $color-font-light;
      }
    }
  }
}

/*
 * Navbar
 */
.navbar-brand {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  font-size: 1rem;
  line-height: 3rem;
  cursor: pointer;
}
.navbar-brand img {
  width: 11rem;
  border-width: 0;
  display: block;
}

.navbar-avatar {
  width: 3rem;
  height: 3rem;
}
