@import '../../css/theme.scss';

.breadcrumb {
  background-color: transparent;
  padding: 0;

  .breadcrumb-item {
    @extend .transition;
    opacity: 0.65;
    font-size: 0.85em;

    a {
      text-decoration: none;
    }

    &:hover {
      opacity: 1;
    }
  }
}

