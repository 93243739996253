/*
 * NoteImages
 */
.image-card {
  overflow: hidden;
  img {
    max-width: 100%;
  }
}

.noteimages-slider {
  transition: opacity ease 0.25s;
  margin-bottom: 20px;

  .noteimages-slider-item,
  img {
    max-height: 120px;
  }

  .slick-list {
      width: 100%;
  }
}

.note-image-modal {
  .note-image-scrollview {
    .note-image-full {
      height: 100%;
      width: 100%;
    
      img {
        max-height: 100%;
        max-width: 100%;
        object-fit: contain;
      }
    }
  
    &.k-scrollview {
      border: none;
      background-color: transparent;
  
      .k-scrollview-nav-wrap {
        background-color: transparent;
      }
    } 
  }
}


.ekko-lightbox h4 {
  font-size: 1.2rem;
  font-weight: normal;
}

.image-hover:hover {
  cursor: pointer;
}

