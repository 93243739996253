@import "../../css/theme";

.collapse-icon {
  @extend .transition;
  opacity: 0.5;

  &:hover {
    opacity: 1;
    color: $secondary;
  }
}
