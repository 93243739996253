@import "../../css/theme";

.submenu-sidebar-nav-item {

  .submenu-sidebar-nav-link {
    color: $color-font-dark;
  }

  &:hover {
    .submenu-sidebar-nav-link {
      color: $color-primary;
    }
  }

  &.active {
    background-color: $color-primary;

    .submenu-sidebar-nav-link {
      color: white;
    }
  }
}

.submenu-sidebar-nav-item {
  cursor: pointer;
}




