@import "../../css/theme";

.link {
  @extend .transition-color;
  color: $color-primary;
  background-color: transparent;

  &:hover,
  &.active {
    color: $color-primary-dark !important;
    text-decoration: none !important;
    background-color: transparent;
  }
}
