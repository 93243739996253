@import "../../css/theme";


.modal {
  overflow: initial !important;

  .modal-content {
    border-style: none !important;
  }

  > .modal-dialog.modal-content > .modal-body,
  > .modal-dialog > .modal-body {
    /* Make the body the scrollable container */
    overflow: auto;
  }

  .modal-header {
    background-color: $color-bg-light;
    border-style: none;
    border-bottom-style: solid;
    border-bottom-color: $color-darken-light;
  }

  .modal-footer {
    background-color: $color-bg-light;
    border-style: none;
    border-top-style: solid;
    border-top-color: $color-darken-light;
  }

  .modal-dialog-slideout-left,
  .modal-dialog-slideout-right {
    transition: all ease 0.25s;
    min-height: 100%;
    height: 100%;
    margin: 0 auto 0 0;
    background: #fff;
    box-shadow: $box-shadow;
    left: 0;
    position: relative;
    width: 100%;
    opacity: 1 !important;

    .modal-content, > form {
      min-height: 100%;
      height: 100%;
      width: 100%;
      overflow: auto;
    }
  }

  .modal-dialog-slideout-right {
    margin: 0 0 0 auto;
    left: auto;
    right: 0;
  }

  .modal-dialog.modal-dialog-slideout-left {
    -webkit-transform: translate(-100%, 0);
    transform: translate(-100%, 0);
    animation: modal-slide-from-left 0.5s ease-in-out;
    animation-direction: reverse;
  }

  .modal-dialog.modal-dialog-slideout-right {
    -webkit-transform: translate(100%, 0);
    transform: translate(100%, 0);
    animation: modal-slide-from-right-back 0.5s ease-in-out;
    animation-fill-mode: forwards;
    animation-direction: normal;
  }

  &.show {
    .modal-dialog.modal-dialog-slideout-left {
    }

    .modal-dialog.modal-dialog-slideout-right {
      animation: modal-slide-from-right 0.5s ease-in-out;
    }
  }
}

@media (max-width: 576px) {
  .modal-dialog.modal-dialog-slideout-left,
  .modal-dialog.modal-dialog-slideout-right {
    width: 90%
  }
}

@keyframes modal-slide-from-right {
  from {
    -webkit-transform: translate(100%, 0);
    transform: translate(100%, 0);
  }

  to {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
  }
}

@keyframes modal-slide-from-right-back {
  from {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
  }

  to {
    -webkit-transform: translate(100%, 0);
    transform: translate(100%, 0);
  }
}

@keyframes modal-slide-from-left {
  from {
    -webkit-transform: translate(-100%, 0);
    transform: translate(-100%, 0);
  }

  to {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
  }
}
