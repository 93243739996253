@import '../../css/theme';
/*
 * SubmenuSidebar
 */
.submenusidebar {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    z-index: 100; /* Behind the navbar */
    padding: 0;
    box-shadow: $box-shadow;

    .nav-item {
        .nav-link {
            @extend .transition;
            font-weight: normal;
        }

        &:hover .nav-link {
            color: $color-font-dark;
        }
    }
}
@media (min-width: $large-screen-up) {
    .submenusidebar {
        .nav-item {
            .nav-link {
                padding-top: 0.75rem;
                padding-bottom: 0.75rem;
            }
        }
    }
}
