﻿@import "fonts";

$font-family-sans-serif: $font;

$color-primary: #FF7F11;
$primary: $color-primary;
$color-primary-light: #ffab63;
$color-primary-dark: #D9531E;

$color-secondary: #0D698B;
$secondary: $color-secondary;
$color-secondary-light: #c8d7de;
$color-secondary-dark: #0b516b;

// scss-docs-start color-variables
$blue: $secondary;
$indigo: #6610f2;
$purple: #6f42c1;
$pink: #d63384;
$red: #dc3545;
$orange: $primary;
$yellow: #ffc107;
$green: #198754;
$teal: #20c997;
$cyan: #0dcaf0;

$color-gray: #737A86;
$color-gray-soft: #ADAEAF;
$color-gray-light: #cccecf;
$color-gray-dark: #32363d;

$light: #fafcfe;
$dark: $color-gray-dark;
$color-bg: #fff;
$color-bg-light: $light;
$color-bg-dark: $dark;

$color-font: #32363d;
$color-font-light: #fff;
$color-font-dark: #000;

$color-darken: rgba(0,0,0,0.1);
$color-darken-light: rgba(0,0,0,0.05);
$color-darken-dark: rgba(0,0,0,0.2);

$color-lighten: rgba(255,255,255,0.1);
$color-lighten-light: rgba(255,255,255,0.05);
$color-lighten-dark: rgba(255,255,255,0.2);

$color-error: #bc1945;

$font-size-base: 0.8rem;
$h1-font-size: $font-size-base * 2.25;
$h2-font-size: $font-size-base * 1.7;
$h3-font-size: $font-size-base * 1.5;
$h4-font-size: $font-size-base * 1.3;
$h5-font-size: $font-size-base * 1.2;
$h6-font-size: $font-size-base;

$headings-font-weight: 700;

$small-screen-up: 544px !default;
$medium-screen-up: 768px !default;
$large-screen-up: 992px !default;
$xl-screen-up: 1200px !default;
$small-screen: 543px !default;
$medium-screen: 767px !default;
$large-screen: 991px !default;
$xl-screen: 1199px !default;

/* Used for interting colours (foreground over background) */
$min-contrast-ratio: 2.5;

$box-shadow: 0 6px 12px rgba(0,0,0,0.03), 0 2px 6px rgba(0,0,0,0.09);
$box-shadow-light: 0 3px 6px rgba(0,0,0,0.03), 0 3px 6px rgba(0,0,0,0.06);

$card-border-width: 0;

$border-radius: 0px;
$border-radius-sm: 0px;
$border-radius-lg: 0px;
$border-radius-xl: 0;
$border-radius-xxl: 0;

$btn-link-disabled-color: rgba(0,0,0,0.5);

.transition {
  transition: all ease 0.25s;
}

.transition-color {
  transition: color ease 0.25s;
}

.transition-opacity {
  transition: opacity ease 0.25s;
}
