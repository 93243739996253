@import "theme.scss";
@import "bootstrap.scss";

body {
  background-color: $color-bg-light;
  /*overflow-x: hidden;*/
}

h3,
h4,
h5,
h6,
.h3,
.h4,
.h5,
.h6 {
  font-weight: normal;
}

a:hover,
a:active {
  text-decoration: none;
}

/*
 * Utilities
 */
.border-top {
  border-top: 1px solid #e5e5e5;
}

.border-bottom {
  border-bottom: 1px solid #e5e5e5;
}

.border-light {
  border-color: $color-darken-light !important;
}

.no-border {
  border-width: 0 !important;
}

.bg-primary-light {
  background-color: $color-primary-light !important;
}

.bg-primary-dark {
  background-color: $color-primary-dark !important;
}

.bg-secondary-light {
  background-color: $color-secondary-light !important;
}

.bg-secondary-dark {
  background-color: $color-secondary-dark !important;
}

.border-danger {
  border-color: $color-error !important;
}

.border-darken {
  border-color: $color-darken !important;
}

.border-lighten {
  border-color: $color-lighten !important;
}

.btn-cancel {
  background-color: rgba(0, 0, 0, 0.05);

  &:hover {
    background-color: rgba(0, 0, 0, 0.1);
  }
}

.text-danger {
  color: $color-error !important;
}
/*
 * Loading
 */
.loading-overlay {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
  width: 100%;
  height: 100%;
}

.loading {
  display: inline-block;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  z-index: 10;

  img {
    -webkit-animation-name: spin;
    -webkit-animation-duration: 2000ms;
    -webkit-animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    -moz-animation-name: spin;
    -moz-animation-duration: 2000ms;
    -moz-animation-iteration-count: infinite;
    -moz-animation-timing-function: linear;
    -ms-animation-name: spin;
    -ms-animation-duration: 2000ms;
    -ms-animation-iteration-count: infinite;
    -ms-animation-timing-function: linear;
    -o-transition: rotate(3600deg);
    transition: rotate(3600deg);
  }
}

.spinner {
  &.fullscreen {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    z-index: 9999;
    padding: 0;
    margin: 0 !important;
    align-items: center;
    background-color: rgba(255, 255, 255, 0.85);
  }
}

.clickable {
  text-decoration: none;

  &:hover {
    cursor: pointer;
    filter: brightness(125%);
  }
}

.text-primary {
  color: $color-primary !important;
}

.text-primary-light {
  color: $color-primary-light !important;
}

.text-primary-dark {
  color: $color-primary-dark !important;
}

.text-soft {
  color: $color-gray-soft !important;
}

.text-lg {
  font-size: 1.75em !important;
}

.text-xl {
  font-size: 3.5em !important;
}

.text-sm {
  font-size: 0.875em !important;
}

.text-xsm {
  font-size: 0.875em !important;
}

.line-spacing-normal {
  line-height: 1.2 !important;
}

.line-height-1 {
  line-height: 1 !important;
}

.text-underline {
  text-decoration: underline !important;
}

.cursor-pointer {
  cursor: pointer !important;
}

.shadow {
  box-shadow: $box-shadow;
}

.form-floating {
  label {
    opacity: 0.85;
    line-height: 1.5rem;
  }
  &.floating-label-fixed {
    label {
      transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
    }
    .k-input input {
      padding-top: 1.625rem;
      padding-bottom: 0.625rem;
      padding-left: 0.75rem;
    }

    .k-input-inner {
      padding-top: 1.625rem;
      padding-bottom: 0.625rem;
      padding-left: 0.75rem;
    }
  }
}

.float-left {
  float: left;
}
.float-right {
  float: right;
}

#main-menu-overlay {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 0%;
  background-color: rgba(0,0,0,0.5);
  z-index: 99;
  opacity: 0;
  transition: opacity ease-in-out 0.35s;
  &.show {
    height: 100%;
    opacity: 1;
  }
}

/* Layout */
#main-content {
  position: relative;
  z-index: 1;
}

#main-menu {
  @extend .transition;
  width: 13rem;
  z-index: 100;

  .sidebar {
    width: 13rem;
    overflow: hidden;

    .nav-item .nav-link span,
    .sidebar-heading span {
      @extend .transition;
      opacity: 1;
    }
  }
}

#main-sidemenu-toggle {
  display: none;
}
#main-sidemenu {
  display: block;
  max-height: auto;
  min-width: 12rem;

  #main-sidemenu-actions {
      .btn {
          width: 100%;
      }
  }
}

@include media-breakpoint-down("lg") {
  #main-menu {
    width: 13rem;
    margin-left: -13rem;
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;

    .sidebar-toggle {
      display: none;
    }

    .sidebar {
      width: 13rem;
    }

    &.expanded {
      margin-left: 0rem;

      .sidebar {
        width: 13rem;

        .nav-item .nav-link span,
        .sidebar-heading span {
          opacity: 1;
          width: auto;
          max-width: unset;
        }
      }
    }
  }

  #main-sidemenu {
    opacity: 0;
    max-height: 0vh;
    transition: all ease-in-out 0.5s;
  }

  #main-sidemenu.show {
    opacity: 1;
    max-height: 100vh;
  }
}

@include media-breakpoint-up("lg") {
  #main-menu {
    width: 3.5rem;
    transition: width ease-in-out 0.35s;
  
    .sidebar {
      width: 3.5rem;
      transition: width ease-in-out 0.35s;

      &.expanded {
        margin-left: 0rem;
      }

      .nav-item {
        transition: all ease-in-out 0.35s;
  
        .nav-link {
          display: flex;
          align-items: center;
          white-space: nowrap;
          overflow: hidden;
          width: 100%;
          transition: width ease-in-out 0.35s;

          .icon-and-text {
            span {
              display: none;
            }
          }
        }
  
        &:hover {
          .nav-link {
            width: auto;
            .icon-and-text {
              span {
                display: inline-block;
              }
            }
          }
        }
      }
    }

    &.expanded, 
    &:hover {
  
      .sidebar {
        width: 13rem;
  
        .nav-item {
          .nav-link {
            .icon-and-text {
              span {
                display: block;
              }
            }
          }
        }
      }
    }

    &.expanded {
      .nav-item.sidebar-toggle {
        .nav-link, .nav-link .fa {
          color: $color-secondary;
        }
      }
    }
  }
  
}

.breadcrumb {
  font-size: 0.85em;
  margin-bottom: 0.35rem;
}

/*
  Grids
*/
  .k-table-thead,
  .k-table-header,
  .k-table-group-sticky-header,
  .k-grid-header,
  .k-grouping-header,
  .k-grid-add-row,
  .k-grid-footer {
    background-color: $color-secondary-dark;
    color: $color-font-light;
  }

  .grid-fullheight {
    display: flex;
    flex-grow: 1;
  }

  .gridrow-clickable {
    tr[data-grid-row-index] {
      cursor: pointer;
    }
  }

  @-moz-keyframes spin {
    from {
      -moz-transform: rotate(0deg);
    }

    to {
      -moz-transform: rotate(360deg);
    }
  }

  @-webkit-keyframes spin {
    from {
      -webkit-transform: rotate(0deg);
    }

    to {
      -webkit-transform: rotate(360deg);
    }
  }

  @keyframes spin {
    from {
      transform: rotate(0deg);
    }

    to {
      transform: rotate(360deg);
    }
  }

  @keyframes fade-out-dnone {
    from {
      opacity: 1;
    }

    to {
      opacity: 0;
      display: none;
    }
  }

  .k-table-row td {
    cursor: pointer;
    filter: brightness(125%);
  }
