.spinner {
    position: relative;
    min-height: 6rem;
    &.fullscreen {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(255,255,255,0.7);
    }
}

.spinner-cti {
  position: absolute;
  left: 50%;
  top: 50%;
  width: 5rem;
  height: 5rem;
  transform: translateX(-50%) translateY(-50%);

  &::after,
  &::before,
  .spinner-cti-inner::after,
  .spinner-cti-inner::before {
    position: absolute;
    left: 0;
    right: 0;
    content: '';
    display: block;
    width: 5rem;
    height: 5rem;
    border-radius: 50%;
    border-style: solid;
    border-width: 0.24rem;
    animation-name: spinner-circle;
    animation-duration: 4s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    transform-origin: center;
  }

  &::after {
    border-top-color: #0D698B;
    border-bottom-color: #0D698B;
    border-right-color: transparent;
    border-left-color: transparent;
  }

  &::before {
    width: 4.3rem;
    height: 4.3rem;
    left: 0.35rem;
    top: 0.35rem;
    border-top-color: transparent;
    border-bottom-color: transparent;
    border-right-color: #737A86;
    border-left-color: #737A86;
    animation-duration: 2s;
    animation-direction: reverse;
  }

  .spinner-cti-inner::after {
    width: 3.6rem;
    height: 3.6rem;
    left: 0.7rem;
    top: 0.7rem;
    border-top-color: #737A86;
    border-bottom-color: #737A86;
    border-right-color: transparent;
    border-left-color: transparent;
    animation-duration: 2s;
  }

  .spinner-cti-inner::before {
    width: 2.9rem;
    height: 2.9rem;
    left: 1.05rem;
    top: 1.05rem;
    border-top-color: transparent;
    border-bottom-color: transparent;
    border-right-color: #737A86;
    border-left-color: #737A86;
    animation-duration: 4s;
    animation-direction: reverse;
  }
}

@keyframes spinner-circle {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
